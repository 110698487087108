/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Bootstrap classes overrides.
 */

.text-white {
  a {
    color: $white;
  }
}

$bg-classes: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  danger: $danger,
  warning: $warning,
  info: $info,
  light: $light,
  dark: $dark,
  body: $body-bg,
  white: $white,
  transparent: transparent
);

.cw-partial-background {
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    height: var(--partial-background-height, 50%);
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  // Simplified z-index handling for .container and .container-fluid
  .container,
  .container-fluid {
    position: relative;
    z-index: 2;
  }
}

// Top and bottom positioning for partial background
.cw-partial-background-bottom::after {
  bottom: 0;
}

.cw-partial-background-top::after {
  top: 0;
}

// Apply background color dynamically based on class
@each $class-name,
  $bg-color in $bg-classes {
  .bg-#{$class-name}.cw-partial-background {
    background-color: transparent !important;

    &::after {
      background-color: $bg-color;
    }
  }
}
