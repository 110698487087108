/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Contains colors, spacing and other theme related sass variables.
 */

// Carousel variables
$cw-carousel-image-height: 500px !default;
$cw-carousel-subtext-height: 200px !default;
$cw-carousel-subtext-medium-height: 238px !default;
$cw-carousel-subtext-small-height: 210px !default;
$cw-carousel-indicators-button-border: 10px solid transparent !default;
$cw-carousel-indicators-width: 30px !default;
$cw-carousel-control-width: 75px !default;

// Tiny-slider variables
$cw-tns-ovh-margin-x: 40px !default;
$cw-tns-controls-width: 30px !default;
$cw-tns-item-margin: 12px !default; 

// Autocomplete variables
$cw-autocomplete-padding-x: 2px !default;

// Card variables
$cw-card-background-height: 290px !default;
$cw-card-background-height-md: 360px !default;
$cw-leading-card-background-height: 490px !default;
$cw-leading-card-background-height-md: 700px !default;
$cw-card-event-background-color: $white !default;
$cw-card-event-color: $dark !default;
$cw-card-event-border-color: rgba($cw-card-event-color, 0.125);
$cw-card-news-background-color: $white !default;
$cw-card-news-color: $dark !default;
$cw-card-news-border-color: rgba($cw-card-news-color, 0.125);
$cw-card-resource-background-color: $white !default;
$cw-card-resource-color: $dark !default;
$cw-card-resource-border-color: rgba($cw-card-resource-color, 0.125);
$cw-card-opportunity-background-color: $white !default;
$cw-card-opportunity-color: $dark !default;
$cw-card-opportunity-border-color: rgba($cw-card-opportunity-color, 0.125);
$cw-card-date-color-weight: if(0 < (0.5 - luminance($cw-card-event-color)), -30%, 30%);
$cw-card-date-color: shift-color($cw-card-event-color, $cw-card-date-color-weight);
$cw-card-border-radius: 8px !default;

// Button variables
$cw-btn-event-background-color: $primary !default;
$cw-btn-event-color: $white !default;
$cw-btn-news-background-color: $primary !default;
$cw-btn-news-color: $white !default;
$cw-btn-resource-background-color: $primary !default;
$cw-btn-resource-color: $white !default;
$cw-btn-opportunity-background-color: $primary !default;
$cw-btn-opportunity-color: $white !default;

// Image variables
$cw-image-border-radius: 0.25rem !default;

// Navbar
$cw-header-logo-image-max-height: 153px !default;
$cw-header-logo-image-max-height-small-devices: 50px !default;
$cw-header-logo-block-width: 20% !default;
$cw-header-menu-block-width: 80% !default;
$cw-header-search-icon-margin-right: map-get($spacers, 1);
$cw-navbar-links-border: 6px solid $primary !default;

// Menu
$cw-menu-popup-container-padding-top: 20px !default;
$cw-menu-popup-container-margin-top: 50px !default;
$cw-menu-popup-container-z-index: 1000 !default;
$cw-login-menu-popup-max-width: 385px !default;
$cw-login-menu-popup-alert-box-padding: 1rem !default;

// Footer
$cw-footer-logo-max-height: 70px !default;

// Container
$cw-narrow-breakpoints: (
  xs: 0,
  sm: 576px,
) !default;
$cw-narrow-inside-full-section-breakpoints: (
  xs: 0,
  sm: 768px,
) !default;
$cw-narrow-inside-50vw-column-breakpoints: (
  xs: 0,
  sm: 1152px,
) !default;
$cw-narrow-max-widths: (
  sm: 540px,
) !default;
$cw-medium-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
) !default;
$cw-medium-inside-full-section-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
) !default;
$cw-medium-inside-50vw-column-breakpoints: (
  xs: 0,
  sm: 1152px,
  md: 1536px,
) !default;
$cw-medium-max-widths: (
  sm: 540px,
  md: 720px,
) !default;
$cw-wide-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
) !default;
$cw-wide-inside-full-section-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
) !default;
$cw-wide-inside-50vw-column-breakpoints: (
  xs: 0,
  sm: 1152px,
  md: 1536px,
  lg: 1984px,
) !default;
$cw-wide-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
) !default;
$cw-full-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;
$cw-full-inside-full-section-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1400px,
) !default;
$cw-full-inside-50vw-column-breakpoints: (
  xs: 0,
  sm: 1152px,
  md: 1536px,
  lg: 1984px,
  xl: 2400px,
) !default;
$cw-full-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;
$cw-card-paragraphs-breakpoints-inside-node: (
  xs: 0,
  sm: 576px,
  md: 1200px,
) !default;

// Grid variables
$cw-two-column-layout-anchor-page-horizontal-gutters: 2rem !default;
$cw-two-column-layout-anchor-page-vertical-gutters: 0 !default;

// Typography variables
$cw-block-quote-author-margin-top-xs: -5.5rem !default;
$cw-block-quote-author-margin-top-sm: -1rem !default;
$cw-block-quote-author-padding-left-sm: 78px !default;
$cw-block-quote-closing-quote-margin-top-xs: 2rem !default;

// Paragraphs variables
$cw-text-with-side-image-paragraph-body-padding: map-get($spacers, 1) !default;

// Fluid variables
$cw-fluid-panels-margin: 1.25rem auto !default;
$cw-fluid-panel-min-width: 28.125em !default;
$cw-fluid-panel-bar-background-color: #f8f9fa !default;
$cw-fluid-panel-bar-height: 40px !default;
$cw-fluid-panel-bar-padding-top: 5px !default;
$cw-fluid-panel-bar-padding-bottom: 5px !default;
$cw-fluid-panel-bar-buttons-padding-top: unset !default;
$cw-fluid-panel-bar-buttons-hover-transition: background-color 0.15s ease-in-out !default;
$cw-fluid-panel-bar-buttons-margin-right: 103px !default;

$cw-fluid-panel-bar-button-padding: 6px 8px !default;
$cw-fluid-panel-bar-button-border-radius: 3px !default;
$cw-fluid-panel-bar-button-background-color: rgba(33, 37, 41, 0.1) !default;
$cw-fluid-panel-bar-button-span-padding-left: 8px !default;
$cw-fluid-panel-bar-button-span-padding-right: 8px !default;
$cw-fluid-panel-bar-reset-button-margin-right: 4px !default;
$cw-fluid-panel-padding: 1.25em !default;
$cw-fluid-panel-border: 0 !default;
$cw-fluid-panel-box-shadow: 0 4px 34px rgba(37, 8, 88, 0.06) !default;
$cw-fluid-panel-border-radius: 8px !default;
$cw-fluid-panel-h2-min-height: unset !default;
$cw-fluid-panel-h2-height: 2.6875rem !default;
$cw-fluid-panel-h2-margin: 0 0 1.25rem 0 !default;
$cw-fluid-panel-h2-text-transform: unset !default;
$cw-fluid-panel-h2-box-shadow: none !default;
$cw-fluid-panel-h2-border-color: rgba($primary, 0.1) !default;
$cw-fluid-panel-h2-align-items: start !default;
$cw-fluid-panel-heading-text-font-size: 1.5rem !default;
$cw-fluid-panel-heading-text-line-height: 32px !default;
$cw-fluid-panel-descr-height: 1.875em !default;
$cw-fluid-panel-form-select-border-width: 1px !default;
$cw-fluid-panel-form-select-width: 13.5rem !default;
$cw-fluid-panel-form-text-field-stepper-align-items: start !default;
$cw-fluid-panel-form-text-field-stepper-focus-container-padding: 1px !default;
$cw-fluid-panel-form-text-field-border-color-alpha: 0.2 !default;
$cw-fluid-panel-form-text-field-border-radius: 3px !default;
$cw-fluid-panel-form-text-field-height: 2.5rem !default;
$cw-fluid-panel-form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$primary}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;
$cw-fluid-panel-icons-descr-height: 1.875em !default;
$cw-fluid-panel-icon-margin-right: 0.875rem !default;
$cw-fluid-panel-icon-margin-bottom: 4px !default;

// Views exposed split filters
$cw-filters-fields-horizontal-spacing: 10px !default;
$cw-filters-icon-horizontal-spacing: 10px !default;
$cw-filters-icon-adjustment-spacing: 30px !default;
$cw-inner-filter-select-input-spacing: 230px !default;

// Members directory variables
$cw-scrollbar-width: 6px;
$cw-map-view-attachment-height: 610px !default;
$cw-card-view-image-height: 280px !default;

// Link variables
$cw-link-bg-color: $primary !default;
$cw-link-color: $white !default;
$cw-link-hover-bg: darken($cw-link-bg-color, 10%);
$cw-link-spacing: 10px;

// My case file upload block
$ssp-my-case-file-upload-width: 100% !default;
$ssp-my-case-file-upload-max-height: 100vh !default;
