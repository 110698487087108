/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Navbar related tweaks to style
 * site navigation bar.
 */

.navbar-brand {
  height: auto;
  line-height: $ssp-header-text-line-height;
  padding: $ssp-header-component-vertical-spacing $ssp-navbar-link-padding;
}

.left-fixed-sidebar {
  li.nav-item {
    margin: 0 !important;

    > a {
      &.nav-link {
        border-radius: 0 !important;
        padding: $ssp-navbar-link-padding !important;
      }
    }
  }
}

ul.nav-pills {
  > li {
    margin: 0;
    padding: 0;

    > a {
      background-color: transparent;
      border-radius: 0;
      color: $ssp-navbar-link-color;
      display: block;

      &.active {
        background-color: $ssp-navbar-link-active-background-color;
        color: $ssp-navbar-link-active-color;
        font-weight: $ssp-navbar-link-active-font-weight;
      }

      &,
      &.active {
        /* stylelint-disable max-nesting-depth */
        @include hover {
          background-color: $ssp-navbar-link-hover-background-color;
          color: $ssp-navbar-link-hover-color;
        }
        /* stylelint-enable */
      }
    }

    .fa {
      margin-right: $ssp-navbar-link-icon-spacing;
      text-align: center;
      // Giving width explicitly in order to align the adjacent links positioning.
      // The width is increased to 125% in order to accomodate the inconsistent icons dimensions.
      width: $ssp-icon-standard-size * 1.25;
    }
  }
}

.nav-stacked {
  > li {
    + li {
      margin-top: 0;
    }
  }
}

.navbar-user-login-menu {
  @include media-breakpoint-up(md) {
    position: relative;

    .bi-key {
      &::before {
        padding: 10px 0 10px 10px;
        rotate: 145deg;
      }
    }
  }
}
